
<template>
  <div class="card" style="margin-top: 18px">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/info'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.info") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/weights'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.weight") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/vaccines'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/diseases'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.diseases") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/feeds'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.feed") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--
            <li class="nav-item">
              <router-link
                to="/animalsVaccine"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link text-active-primary me-6"
                to="/feed"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.feed") }}
              </router-link>
            </li>
                          -->
          </ul>
        </div>
        <!--
        <div class="card-header border-0" style="padding: 0">
          <div class="card-title">
            {{ $t("menuPages.animalList.animalList") }}
          </div>
          <div
            class="d-flex align-items-center py-1"
          >
            <a
              href="/createAnimal"
              class="btn btn-sm btn-primary"
              id="kt_toolbar_primary_button"
            >
              {{ $t(PAGE_TITLE_PATH.ANIMAL_CREATE) }}
            </a>
          </div>
        </div>
        -->
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <!--begin::Content-->
        <div v-else id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
            @submit="handleSubmit"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 pb-2">
              <div class="row mb-6">
                <div class="col-md-6">
                  <div class="row">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                      $t("menuPages.createAnimal.photo")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Col-->

                    <div class="col-lg-8">
                      <div
                        class="image-input image-input-outline"
                        data-kt-image-input="true"
                        style="background-image: url(media/avatars/blank.png)"
                      >
                        <div
                          class="image-input-wrapper w-175px h-175px"
                          :style="`background-image: url(${animalModel.profilePhotoUrl})`"
                        ></div>
                        <label
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-white
                            shadow
                          "
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Change avatar"
                        >
                          <i class="bi bi-pencil-fill fs-7"></i>
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                            @change="onFileChange($event)"
                          />

                          <Field type="hidden" name="avatar_remove" />
                        </label>

                        <span
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-white
                            shadow
                          "
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          @click="removeImage()"
                          title="Remove avatar"
                        >
                          <i class="bi bi-x fs-2"></i>
                        </span>
                      </div>

                      <div class="form-text">
                        {{ $t("menuPages.createAnimal.photoDesc") }}
                      </div>
                    </div>

                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{
                        $t("menuPages.createAnimal.earringNumber")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Select-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.earTag"
                        type="string"
                        name="earTag"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="
                          $t('menuPages.createAnimal.earringNumber')
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span class="required">{{
                        $t("menuPages.createAnimal.race")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.animalBreedName"
                        type="text"
                        name="name"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('menuPages.createAnimal.race')"
                        style="background-color: gainsboro"
                        readonly
                      />
                      <!--
                      <Field
                      v-model="animalModel.animalBreedId"
                        name="breed"
                        data-control="select1"
                        data-hide-search="true"
                        placeholder="$t('menuPages.createAnimal.selectRace')"
                        class="form-select form-select-solid"
                        as="select"
                      >
                        <option
                          v-for="breed in breedList"
                          :key="breed.breed.id"
                          :value="breed.breed.id"
                        >
                          {{ breed.breed.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="breed" />
                        </div>
                      </div>
                      -->
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.nickname") }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.name"
                        type="text"
                        name="name"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.nickname')"
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.birthDate") }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="
                          animalModel.birthDate.toISOString().split('T')[0]
                        "
                        type="date"
                        :max="new Date().toISOString().split('T')[0]"
                        name="color"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.birthDate')"
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.color") }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.color"
                        type="text"
                        name="color"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('menuPages.createAnimal.color')"
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span class="required">{{
                        $t("menuPages.createAnimal.gender")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <Field
                        v-model="animalModel.gender"
                        name="gender"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder='{{ $t("menuPages.createAnimal.selectGender") }}'
                        class="form-select form-select-solid"
                        as="select"
                      >
                        <option
                          v-for="gender in Object.values(GenderEnum).filter(
                            (value) => typeof value != 'number'
                          )"
                          :key="gender"
                          :value="gender"
                        >
                          {{
                            gender.toString().charAt(0).toUpperCase() +
                            gender.toString().slice(1).toLowerCase()
                          }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="gender" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span
                        >{{
                          $t("menuPages.createAnimal.lastWeight")
                        }}
                        (Kilo)</span
                      >
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.liveWeight"
                        type="number"
                        min="50"
                        max="5000"
                        name="weight"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.lastWeight')"
                        readonly
                      />
                    </div>
                    <!--begin::Edit-->
                    <!--
                    <a
                      href="#"
                      class="btn btn-icon btn-success w-40px h-45px me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_new_card"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        title=""
                        data-bs-original-title="Edit"
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/arrows/arr085.svg"
                          />
                        </span>
                      </span>
                    </a>
--><!--
                    <a
                      class="btn btn-icon btn-success w-40px h-45px me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_new_card"
                      style="pointer-events: none"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        title=""
                        data-bs-original-title="Edit"
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/arrows/arr085.svg"
                          />
                        </span>
                      </span>
                    </a> -->
                    <!--end::Edit-->
                    <!--end::Col-->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.price") }} (₺)</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.acquisitionPrice"
                        type="number"
                        min="1"
                        name="price"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.price')"
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{
                        $t("menuPages.createAnimal.motherEarNumber")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Select-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.motherEarTag"
                        type="string"
                        name="motherEarTag"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="
                          $t('menuPages.createAnimal.motherEarNumber')
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{
                        $t("menuPages.createAnimal.fatherEarNumber")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.fatherEarTag"
                        type="text"
                        name="fatherEarTag"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="
                          $t('menuPages.createAnimal.fatherEarNumber')
                        "
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2" style="border-top: 1px solid #eff2f5">
                <h4 class="fw-bolder mt-6">
                  {{ $t("menuPages.createAnimal.companyInfo") }}
                </h4>
                <div class="col-md-6 mt-4">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{
                        $t("menuPages.createAnimal.companyFirstDate")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="
                          animalModel.acquisitionDate
                            .toISOString()
                            .split('T')[0]
                        "
                        type="date"
                        :max="new Date().toISOString().split('T')[0]"
                        name="acquisitionDate"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="
                          $t('menuPages.createAnimal.companyFirstDate')
                        "
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
                <div class="col-md-6 mt-4">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{
                        $t("menuPages.createAnimal.companyEnterDesc")
                      }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.acquisitionReason"
                        type="text"
                        name="acquisitionReason"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="
                          $t('menuPages.createAnimal.companyEnterDesc')
                        "
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2" style="border-top: 1px solid #eff2f5">
                <div class="col-md-6 mt-4">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.barnyard") }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.barnyard"
                        type="text"
                        name="barnyard"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.barnyard')"
                        readonly
                      />
                    </div>
                    <!--
                    <a
                      class="btn btn-icon btn-success w-40px h-45px me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_new_card"
                      style="pointer-events: none"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        title=""
                        data-bs-original-title="Edit"
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/arrows/arr085.svg"
                          />
                        </span>
                      </span>
                    </a>
                    -->
                    <!--end::Col-->
                  </div>
                </div>
                <div class="col-md-6 mt-4">
                  <div class="row mb-5">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.status") }}</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-6 fv-row">
                      <input
                        v-model="animalModel.status"
                        type="text"
                        name="status"
                        class="form-control form-control-lg form-control-solid"
                        style="background-color: gainsboro"
                        :placeholder="$t('menuPages.createAnimal.selectStatus')"
                        readonly
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-12 mt-4">
                  <div class="row mb-5">
                    <label class="col-lg-2 col-form-label fw-bold fs-6">
                      <span>{{ $t("menuPages.createAnimal.tags") }}</span>
                      <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="$t('menuPages.createAnimal.tagsValid')"
                      ></i>
                    </label>
                    <!--end::Label-->
                    <div class="col-lg-9 fv-row">
                      <el-select
                        class="col-lg-5"
                        v-model="animalModel.animalTags"
                        multiple
                        placeholder="Seçiniz..."
                        @change="checkSelectedOptions"
                      >
                        <el-option
                          v-for="animalTag in animalTagList.filter(
                            (value) => typeof value != 'number'
                          )"
                          :key="animalTag.animalTag"
                          :label="animalTag.animalTag.name"
                          :value="animalTag.animalTag.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <!--begin::Submit button-->
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label">
                  {{ $t("common.button.update") }}
                </span>
                <span class="indicator-progress">
                  {{ $t("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Submit button-->
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
  <Vaccinate></Vaccinate>
  <AddWeight></AddWeight>
  <AddDisease></AddDisease>
  <Feed></Feed>
  <AnimalTransfer></AnimalTransfer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useStore } from "vuex";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { useRoute, useRouter } from "vue-router";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: {
    Field,
    Form,
    Vaccinate,
    AddWeight,
    AddDisease,
    Feed,
    AnimalTransfer,
  },
  methods: {
    checkSelectedOptions(selectedOptions) {
      if (selectedOptions && selectedOptions.length > 3) {
        if (this.animalModel.animalTags)
          this.animalModel.animalTags = this.animalModel.animalTags.slice(0, 3);
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const animalId = route.params["id"];
    const animalTagList = ref<AnimalTagListModel[]>([]);

    const animalTagController =
      store.state.ControllersModule.animalTagController;
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const breedList = ref<BreedListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const animalModel = ref<AnimalDetailModel>({
      birthDate: new Date(),
      acquisitionDate: new Date(),
    });

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      isLoading.value = true;

      getBreeds();
      getAnimalTags();
      getAnimal(Number(animalId));
    });

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimal = (animalId: number) => {
      const animalDetailModel: AnimalDetailModel = {
        id: animalId,
        birthDate: new Date(),
        acquisitionDate: new Date(),
      };

      animalController
        .getAnimal(animalDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalModel.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimalTags = async () => {
      animalTagList.value = [];

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: 1,
        pageSize: 50,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              animalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const isSelected = (tagId) => {
      if (animalModel.value.animalTags)
        return animalModel.value.animalTags.includes(tagId);
    };

    const handleSubmit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      -animalController
        .updateAnimal(animalModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {});
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const removeImage = () => {
      animalModel.value.profilePhotoUrl = "/media/avatars/blank.png";
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          animalModel.value.profilePhotoUrl = e.target.result.toString();
          const imageFile = new File([file], "profilPhoto.jpg", {
            type: file.type,
          });

          animalModel.value.profilePhoto = imageFile;
        }
      };

      reader.readAsDataURL(file);
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      PAGE_TITLE_PATH,
      animalId,
      animalTagList,
      breedList,
      translate,
      animalModel,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      isLoading,
      isSelected,
      onFileChange,
      removeImage,
      handleSubmit,
    };
  },
});
</script>
