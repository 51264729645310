
import { defineComponent, onMounted, ref } from "vue";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useStore } from "vuex";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { useRoute, useRouter } from "vue-router";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: {
    Field,
    Form,
    Vaccinate,
    AddWeight,
    AddDisease,
    Feed,
    AnimalTransfer,
  },
  methods: {
    checkSelectedOptions(selectedOptions) {
      if (selectedOptions && selectedOptions.length > 3) {
        if (this.animalModel.animalTags)
          this.animalModel.animalTags = this.animalModel.animalTags.slice(0, 3);
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const animalId = route.params["id"];
    const animalTagList = ref<AnimalTagListModel[]>([]);

    const animalTagController =
      store.state.ControllersModule.animalTagController;
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const breedList = ref<BreedListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const animalModel = ref<AnimalDetailModel>({
      birthDate: new Date(),
      acquisitionDate: new Date(),
    });

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      isLoading.value = true;

      getBreeds();
      getAnimalTags();
      getAnimal(Number(animalId));
    });

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimal = (animalId: number) => {
      const animalDetailModel: AnimalDetailModel = {
        id: animalId,
        birthDate: new Date(),
        acquisitionDate: new Date(),
      };

      animalController
        .getAnimal(animalDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(animalModel.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimalTags = async () => {
      animalTagList.value = [];

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: 1,
        pageSize: 50,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              animalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const isSelected = (tagId) => {
      if (animalModel.value.animalTags)
        return animalModel.value.animalTags.includes(tagId);
    };

    const handleSubmit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      -animalController
        .updateAnimal(animalModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {});
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const removeImage = () => {
      animalModel.value.profilePhotoUrl = "/media/avatars/blank.png";
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          animalModel.value.profilePhotoUrl = e.target.result.toString();
          const imageFile = new File([file], "profilPhoto.jpg", {
            type: file.type,
          });

          animalModel.value.profilePhoto = imageFile;
        }
      };

      reader.readAsDataURL(file);
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      PAGE_TITLE_PATH,
      animalId,
      animalTagList,
      breedList,
      translate,
      animalModel,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      isLoading,
      isSelected,
      onFileChange,
      removeImage,
      handleSubmit,
    };
  },
});
